import React, { useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import PrimaryButton from "../../PrimaryButton";
import SecondaryButton from "../../SecondaryButton";
import SensorSignal from "../../SensorSignal";
import InstallSensorLocation from "../../InstallSensorLocation";
import AltSpinner from "../../Spinners/AltSpinner";
import { useViewport } from "../../ViewportProvider";
import { INSTALL_INSTRUCTIONS } from "../../../Services/Constants";
import { InfoIcon } from "../../../Styles/Icons/DesignSystem/InfoIcon";

const SensorCard = ({
  isHub,
  error,
  sensor,
  sensors,
  loading,
  onConnect, // used on the hub screen
  onViewGuide, // used on the hub and sensors screen
  onViewTroubleShooting, // used on the sensors screen only
  troubleShootingLabel, // used on sensors screen only
  showMissingLocationMessage,
  sensorLocations,
  connectionsChecked,
  onBackButton,
  onNextButton,
}) => {
  const instructions = INSTALL_INSTRUCTIONS[sensor?.label];
  const { width } = useViewport();
  const isMobile = width <= 600;
  const isVerySmallScreen = width <= 290;
  const sensorData = sensors?.find((s) => s?.name === sensor?.name);
  const showLocationError =
    sensor?.label !== "h1" &&
    showMissingLocationMessage &&
    !sensorLocations?.[sensor?.name] &&
    sensor.locationRequired === true;
  const showCannotConnectError =
    error &&
    sensor?.label !== "h1" &&
    connectionsChecked[sensor?.label] === true &&
    !sensorData?.online &&
    !loading;

  const connectButtonLabel = sensorData?.online ? "Connected" : "Connect me";

  const videoRef = useRef();
  useEffect(() => {
    videoRef.current?.load();
  }, [sensor.video]);

  return (
    <div
      className={
        showCannotConnectError
          ? styles.sensor_card_error
          : styles.sensor_card_default
      }
    >
      <div className={styles.top_sensor_card}>
        <div>{instructions?.title}</div>
        {!isHub && !sensorData?.online ? (
          <SecondaryButton
            onClick={onViewTroubleShooting}
            style={{ borderRadius: "8px", backgroundColor: theme.brandOrange }}
          >
            {troubleShootingLabel}
          </SecondaryButton>
        ) : (
          <SensorSignal
            online={sensorData?.online}
            loading={sensorData?.loading}
          />
        )}
      </div>
      <div
        className={
          isHub
            ? styles.location_instructions_hub
            : styles.location_instructions
        }
      >
        <div className={styles.location_text}>
          Location: <span>{instructions?.locationInstructions}</span>
        </div>
        {!isHub && (
          <div
            onClick={() => onViewGuide(true)}
            className={styles.more_info_sensors}
          >
            <InfoIcon colour={theme.primary5} />
          </div>
        )}
      </div>
      <div className={styles.video_container}>
        <img
          key={sensor.name}
          src={sensor?.splinePNG}
          alt={sensor?.alt}
          style={{
            height: isMobile ? "auto" : "200px",
            width: isMobile ? "100%" : "auto",
            borderRadius: "8px",
          }}
        />
        {/* {isHub ? (
          <img
            key={sensor.name}
            src={sensor?.image}
            alt={sensor?.alt}
            style={{
              height: "200px",
              borderRadius: "8px",
            }}
          />
        ) : (
          <video
            ref={videoRef}
            disablePictureInPicture
            id={sensor.name}
            loop
            muted
            autoPlay
            controlsList="nodownload nofullscreen noremoteplayback"
            playsInline
            className={styles.video}
            src={sensor.video}
            type="video/webm"
          />
        )} */}
      </div>
      <InstallSensorLocation
        sensor={sensor}
        showLocationError={showLocationError}
        isHub={isHub}
      />
      {isHub && (
        <div className={styles.hub_sensor_card_buttons}>
          <PrimaryButton
            onClick={() => onViewGuide(true)}
            style={{
              width: "100%",
              marginRight: "12px",
              backgroundColor: "white",
              borderRadius: "8px",
              border: `1px solid ${theme.primary3}`,
              lineHeight: 1.75,
              padding: "6px 8px",
            }}
          >
            {isVerySmallScreen ? "Guide" : " View guide"}
          </PrimaryButton>
          <PrimaryButton
            disabled={loading}
            onClick={onConnect}
            style={{ width: "100%", borderRadius: "8px" }}
            loading={loading}
            startIcon={loading && <AltSpinner small />}
          >
            {connectButtonLabel}
          </PrimaryButton>
        </div>
      )}
      {!isHub && (
        <div className={styles.sensor_card_buttons}>
          <PrimaryButton
            onClick={() => onBackButton("previous")}
            style={{
              width: "100%",
              marginRight: "12px",
              backgroundColor: "white",
              borderRadius: "8px",
              border: `1px solid ${theme.primary3}`,
              lineHeight: 1.75,
              padding: "6px 8px",
            }}
          >
            {"Previous sensor"}
          </PrimaryButton>
          <PrimaryButton
            disabled={loading}
            onClick={() => onNextButton("next")}
            style={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: "8px",
              border: `1px solid ${theme.primary3}`,
              lineHeight: 1.75,
              padding: "6px 8px",
            }}
          >
            {"Next sensor"}
          </PrimaryButton>
        </div>
      )}

      {showLocationError ? (
        <div className={styles.error_container}>
          <p className={styles.error_message}>
            Please select a location for this sensor
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default SensorCard;
