import React from "react";
import styles from "./styles.module.scss";
import { NotificationsUnderline } from "../../Styles/Shapes/NotificationsUnderline";

const DashboardNotificationsList = () => {
  return (
    <div className={styles.notifications_list_container}>
      <div className={styles.underline_container}>
        <NotificationsUnderline />
      </div>
      <div className={styles.no_more_notifications_text}>
        No more notifications today
      </div>
    </div>
  );
};

export default DashboardNotificationsList;
