import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import Tooltip from "../Tooltip";
import Line from "../Line";
import {
  AtRiskIcon,
  ExpectedIcon,
  InfoIcon,
  UnexpectedIcon,
} from "../../Styles/Icons/DesignSystem";
import { RiskLevel, RiskLevelText } from "@intelligentlilli/lilli-utils";
import { NEWLY_INSTALLED } from "../../Services/Constants";

const ServiceUserRiskStatusTooltip = ({
  navigate,
  uniqueContributingBehaviours,
  user,
  centerTooltip,
  behavioursAffectingStatus,
}) => {
  const TODAYS_DATE = new Date().toISOString().slice(0, 10);

  // Get the correct message for the tooltip
  const tooltipRiskStatus = (() => {
    switch (user.daysSinceRiskStatusChanged) {
      case 0:
        return "less than a day";
      case 1:
        return "1 day";
      case "Newly installed":
        return "Newly installed";
      case "No change":
        return "more than a week";
      case undefined:
        return "an unknown period of time";
      default:
        return `${user.daysSinceRiskStatusChanged} days`;
    }
  })();

  // Get the riskText for the tooltip
  const RiskText = RiskLevelText[RiskLevel[user.riskStatus]];

  return (
    <Tooltip
      top={centerTooltip ? "-150px" : "-80px"}
      left={centerTooltip ? "0px" : "150px"}
    >
      <div data-private className={styles.tooltip_title}>
        {user.userstring}
      </div>
      <Line className={styles.line} />
      <div className={styles.tooltip_content}>
        {user.installationStatus === "complete" ? (
          <div
            className={styles.icon}
            style={{
              border: `2px solid ${theme.neutral7}`,
            }}
          >
            {user.riskStatus === 2 && (
              <AtRiskIcon colour={theme.neutral7} width={12} />
            )}
            {user.riskStatus === 1 && (
              <UnexpectedIcon colour={theme.neutral7} width={12} />
            )}
            {user.riskStatus === 0 && (
              <ExpectedIcon colour={theme.neutral7} width={12} />
            )}
          </div>
        ) : (
          <div>
            <InfoIcon width={20} colour={theme.risk3} />
          </div>
        )}
        <div className={styles.tooltip_content_right}>
          <div className={styles.tooltip_content_right_title}>{RiskText}</div>
          {tooltipRiskStatus === "Newly installed" ? (
            <div>{NEWLY_INSTALLED}</div>
          ) : user?.installationStatus === "complete" &&
            tooltipRiskStatus !== "Newly installed" ? (
            <div data-private>
              {user?.serviceUserProperties?.forenames || user?.userstring} has
              been {RiskText} for {tooltipRiskStatus}
              {user?.riskStatus ? (
                <>
                  {` because of these behaviour${
                    uniqueContributingBehaviours === 1 ? "" : "s"
                  }: `}
                  {behavioursAffectingStatus?.map((behaviourItem, index) => {
                    const behaviour = behaviourItem.toLowerCase().trim();
                    const urlBehaviour =
                      behaviour === "movement" ||
                      behaviour === "night-time activity"
                        ? "overview"
                        : behaviour?.includes("eating")
                          ? "sustenance"
                          : behaviour === "home temperature"
                            ? "temperature"
                            : behaviour.toLowerCase().trim();
                    const url = `/lilli-users/${user?.id}?tab=${urlBehaviour}&view=day&start=${TODAYS_DATE}&end=${TODAYS_DATE}`;
                    if (index === behavioursAffectingStatus.length - 2) {
                      return (
                        <span>
                          <span
                            className={styles.clickable_behaviour}
                            onClick={() => navigate(url)}
                          >
                            {behaviourItem.trim()}
                          </span>{" "}
                          and{" "}
                        </span>
                      );
                    } else if (index === behavioursAffectingStatus.length - 1) {
                      return (
                        <span
                          className={styles.clickable_behaviour}
                          onClick={() => navigate(url)}
                        >
                          {behaviourItem.trim()}
                        </span>
                      );
                    } else {
                      return (
                        <span>
                          <span
                            className={styles.clickable_behaviour}
                            onClick={() => navigate(url)}
                          >
                            {behaviourItem.trim()}
                          </span>
                          {", "}
                        </span>
                      );
                    }
                  })}
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div>Installation incomplete</div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default ServiceUserRiskStatusTooltip;
